<template>
  <div v-if="viewport" class="robot">
    <div>
      <banner :nav-index="3">
        <template slot="banner">
          <div class="banner">
            <div class="center" :style="{'will-change': 'transform',transform: `translate3d(0,${transLateY}px,0)`}">
              <div class="text">
                <h1>
                  人形智能
                </h1>
                <div class="line"></div>
                <p>
                    基于人形机器人，结合穿戴交互技术，实现人机情感与动作孪生交互。 让机器人成为人类情感的载体与延伸，从而形成更好的感官能力，实现人与机器人跨越空间的真实感知互动体验。
                </p>
              </div>
            </div>
          </div>
        </template>
      </banner>
    </div>
    <div style="position: relative;z-index: 2;background-color: #fff">
      <div>
        <chaperonage></chaperonage>
      </div>
      <div class="footer-box">
        <relation></relation>
      </div>
    </div>

  </div>
  <div v-else class="phone-wrap">
    <div class="background">
      <div class="nav">
        <div class="logo">
          <img src="@/assets/phone/phone-logo.png">
        </div>
        <div @click="onList" class="fork">
          <span class="iconfont icon-liebiaoshitucaidan"></span>
        </div>
      </div>
      <div class="title">
        <h1>人形智能</h1>
      </div>

      <div v-show="list" class="list">
        <phoneNav @onHide="onHide"></phoneNav>
      </div>
    </div>
    <div class="text">
      <p>
          基于人形机器人，结合穿戴交互技术，实现人机情感与动作孪生交互。 让机器人成为人类情感的载体与延伸，从而形成更好的感官能力，实现人与机器人跨越空间的真实感知互动体验。
      </p>
    </div>
    <div class="smart" >
    <div class="chaperonage" >
      <img src="@/assets/phone/chaperonage2.png">
    </div>
      <div class="introduce" >
        <div>
          <h4>
            智能<br />
            陪护机器人
          </h4>
          <div class="line" ></div>
          <p>
            类脑智能技术下的智能情感陪护机器人
          </p>
        </div>

      </div>
    </div>
    <div class="smart reverse">
      <div class="chaperonage" >
        <img src="@/assets/phone/nursenewphone.png">
      </div>
      <div class="introduce" >
        <div>
          <h4>
            孪生机器人
          </h4>
          <div class="line" ></div>
          <p>
            穿戴控制下的机器人孪生应用
          </p>
        </div>
      </div>
    </div>
    <div class="smart" >
      <div class="chaperonage" >
        <img src="@/assets/phone/imgshuai4_1.png">
      </div>
      <div class="introduce" >
        <div>
          <h4>
            情感俱身智能
          </h4>
          <div class="line" ></div>
          <p class="broad" >
            人机协同下的情感表达
          </p>
        </div>
      </div>
    </div>
    <div class="footer" >
      <phoneFooter></phoneFooter>
    </div>
  </div>
</template>

<script>
import banner from '@/components/banner'
import chaperonage from './components/chaperonage'
import relation from '@/components/footer'
import phoneNav from '@/components/phoneNav/index'
import phoneFooter from "@/components/phoneFooter";

export default {
  name: "robot.vue",
  components: {
    phoneNav,
    banner,
    chaperonage,
    relation,
    phoneFooter,
  },
  watch: {
    screenWidth: function (n, o) {
      if (n <= 1024) {
        this.viewport=false
      } else {
        this.viewport=true
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    this.screenWidth = document.body.clientWidth
    if (document.documentElement.clientWidth < 1280) {
      this.viewport = false
    }
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth

      })()
    }
  },
  data() {
    return {
      toTop: 0,
      transLateY: 0,
      viewport: true,
      screenWidth:null,
      list: false,
    }

  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop < 900) {
        if (this.toTop <= scrollTop) {
          this.transLateY = scrollTop / 10
        } else {
          this.transLateY = -scrollTop / 10
        }
      }
      this.toTop = scrollTop
    },

    onList() {
      setTimeout(() =>{
        this.list = true
      }, 200)

    },

    onHide() {
      setTimeout(() =>{
        this.list = false
      }, 200)

    },

  }
}
</script>

<style scoped>


.banner .center {
  margin: 0 auto;
  width: 1200px;
}

.center .text {
  padding-top: 138px;
}

.center .text h1 {
  font-weight: 600;
  font-size: 54px;
  line-height: 76px;
  color: #FFFFFF;
  font-family: 'PingFang SC';
  font-style: normal;
  letter-spacing: 2px;
}

.center .text .line {
  margin-top: 60px;
  width: 60px;
  height: 6px;
  background-color: #FFFFFF;
}

.center .text p {
  margin-top: 60px;
  font-weight: 400;
  font-size: 24px;
  line-height: 50px;
  color: #FFFFFF;
  opacity: 0.8;
  letter-spacing: 2px;
}

.footer-box {
  padding-top: 190px;
}
  .phone-wrap {
    font-family: 'PingFang SC';
  }

  .nav {
    display: flex;
    justify-content: space-between;
    padding: 1.14rem 0.6rem 0;
  }

  .nav .logo {
    display: flex;
    align-items: center;
    height: 0.43rem;
  }

  .nav .logo img {
    height: 0.35rem;
  }

  .nav .fork {
    height: 0.43rem;
    line-height: 0.43rem;
    color: #FFFFFF;
  }

  .nav .fork .iconfont {
    font-size: 0.36rem;
  }

  .list {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .background {
    background-image: url("../../assets/phone/chaperonage.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .background .title {
    padding: 1.6rem 0 2.06rem 0.7rem;
  }

  .background .title h1 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.46rem;
    line-height: 0.64rem;
    color: #FFFFFF;
    letter-spacing: 0.03rem;
  }

  .phone-wrap .text {
    padding: 0.8rem 0.6rem 1.6rem;
  }

  .phone-wrap .text p {
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.5rem;
    color: #111111;
    /*letter-spacing: 0.03rem;*/
  }

  .smart {
    padding-bottom: 2rem;
    display: flex;
    line-height: 0;
  }


  .reverse {
    display: flex;
    flex-direction: row-reverse;
  }

  .smart .chaperonage {
    line-height: 0;
  }


  .smart .chaperonage img {
    width: 4.7rem;
  }

  .smart .introduce {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
  }

  .reverse .introduce {
    margin-left: 0;
    margin-right: 0.5rem;
  }


  .smart .introduce h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.32rem;
    line-height: 0.45rem;
    letter-spacing: 0.01rem;
  }

  .smart .introduce .line {
    margin-top: 0.3rem;
    width: 0.24rem;
    height: 0.03rem;
    background-color: #000000;
  }

  .smart .introduce p {
    margin-top: 0.3rem;
    width: 1.7rem;
    height: 1.2rem;
    font-style: normal;
    font-weight: 400;
    font-size: 0.24rem;
    line-height: 0.4rem;
    color: #111111;
    opacity: 0.5;
    letter-spacing: 0.01rem;
  }

  .smart .introduce .broad {
    width: 1.45rem;
  }
</style>
