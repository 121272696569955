var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.viewport)?_c('div',{staticClass:"robot"},[_c('div',[_c('banner',{attrs:{"nav-index":3}},[_c('template',{slot:"banner"},[_c('div',{staticClass:"banner"},[_c('div',{staticClass:"center",style:({'will-change': 'transform',transform: `translate3d(0,${_vm.transLateY}px,0)`})},[_c('div',{staticClass:"text"},[_c('h1',[_vm._v(" 人形智能 ")]),_c('div',{staticClass:"line"}),_c('p',[_vm._v(" 基于人形机器人，结合穿戴交互技术，实现人机情感与动作孪生交互。 让机器人成为人类情感的载体与延伸，从而形成更好的感官能力，实现人与机器人跨越空间的真实感知互动体验。 ")])])])])])],2)],1),_c('div',{staticStyle:{"position":"relative","z-index":"2","background-color":"#fff"}},[_c('div',[_c('chaperonage')],1),_c('div',{staticClass:"footer-box"},[_c('relation')],1)])]):_c('div',{staticClass:"phone-wrap"},[_c('div',{staticClass:"background"},[_c('div',{staticClass:"nav"},[_vm._m(0),_c('div',{staticClass:"fork",on:{"click":_vm.onList}},[_c('span',{staticClass:"iconfont icon-liebiaoshitucaidan"})])]),_vm._m(1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.list),expression:"list"}],staticClass:"list"},[_c('phoneNav',{on:{"onHide":_vm.onHide}})],1)]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"footer"},[_c('phoneFooter')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("@/assets/phone/phone-logo.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('h1',[_vm._v("人形智能")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" 基于人形机器人，结合穿戴交互技术，实现人机情感与动作孪生交互。 让机器人成为人类情感的载体与延伸，从而形成更好的感官能力，实现人与机器人跨越空间的真实感知互动体验。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smart"},[_c('div',{staticClass:"chaperonage"},[_c('img',{attrs:{"src":require("@/assets/phone/chaperonage2.png")}})]),_c('div',{staticClass:"introduce"},[_c('div',[_c('h4',[_vm._v(" 智能"),_c('br'),_vm._v(" 陪护机器人 ")]),_c('div',{staticClass:"line"}),_c('p',[_vm._v(" 类脑智能技术下的智能情感陪护机器人 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smart reverse"},[_c('div',{staticClass:"chaperonage"},[_c('img',{attrs:{"src":require("@/assets/phone/nursenewphone.png")}})]),_c('div',{staticClass:"introduce"},[_c('div',[_c('h4',[_vm._v(" 孪生机器人 ")]),_c('div',{staticClass:"line"}),_c('p',[_vm._v(" 穿戴控制下的机器人孪生应用 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smart"},[_c('div',{staticClass:"chaperonage"},[_c('img',{attrs:{"src":require("@/assets/phone/imgshuai4_1.png")}})]),_c('div',{staticClass:"introduce"},[_c('div',[_c('h4',[_vm._v(" 情感俱身智能 ")]),_c('div',{staticClass:"line"}),_c('p',{staticClass:"broad"},[_vm._v(" 人机协同下的情感表达 ")])])])])
}]

export { render, staticRenderFns }