<template>
  <div class="chaperonage">
    <div class="img-text">
      <div class="picture">
        <img src="@/assets/robot_1.png">
      </div>
      <div class="text">
        <h2>智能陪护机器人</h2>
        <p>类脑智能技术下的</p>
        <p>智能情感陪护机器人</p>
      </div>
    </div>
    <div class="img-text reversal">
      <div class="picture">
        <img src="@/assets/nursenew.png">
      </div>
      <div class="text">
        <h2>孪生机器人</h2>
        <p>穿戴控制下的</p>
        <p>机器人孪生应用</p>
      </div>
    </div>
    <div class="img-text">
      <div class="picture">
        <img src="@/assets/imgshuai4.png">
      </div>
      <div class="text">
        <h2>情感俱身智能</h2>
        <p>人机协同下的情感表达</p>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "chaperonage.vue"
  }
</script>

<style scoped>

  .img-text {
    display: flex;
    box-sizing: border-box;
    padding-top: 192px;
  }

  .img-text .picture {
    display: flex;
    width: 57%;
    align-items: center;
  }

  .img-text .picture img {
    width: 100%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .img-text .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 100px;
  }

  .img-text h2 {
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 54px;
    line-height: 76px;
  }

  .img-text p {
    font-size: 30px;
    line-height: 70px;
  }

  .reversal {
    flex-direction: row-reverse;
  }

  .reversal .picture img {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  .reversal .text {
    margin-right: 100px;
    margin-left: 0;
  }

</style>
